import { render, staticRenderFns } from "./EditClasses.vue?vue&type=template&id=572d68e9&scoped=true"
import script from "./EditClasses.vue?vue&type=script&lang=js"
export * from "./EditClasses.vue?vue&type=script&lang=js"
import style0 from "./EditClasses.vue?vue&type=style&index=0&id=572d68e9&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "572d68e9",
  null
  
)

export default component.exports