<template>
    <div>
        <b-modal id="excel-import-modal" title="Charger un fichier d'inscription" @hidden="reset" @show="getCustomers">
            <template #default>
                <b-form ref="form">
                    <div v-if="importReport.errors?.length || importReport.success?.length" class="import-report">
                        <div v-if="importReport.errors?.length" class="report-section error-section">
                            <div class="section-header">
                                <i class="fas fa-exclamation-circle"></i>
                                <h6 class="mb-0">Erreurs d'import</h6>
                            </div>
                            <ul class="mb-0">
                                <li v-for="(error, index) in importReport.errors" :key="index">
                                    {{ error[0] }}
                                </li>
                            </ul>
                        </div>
                        <div v-if="importReport.success?.length" class="report-section success-section">
                            <div class="section-header">
                                <i class="fas fa-check-circle"></i>
                                <h6 class="mb-0">Imports réussis</h6>
                            </div>
                            <ul class="mb-0">
                                <li v-for="(success, index) in importReport.success" :key="index">
                                    {{ success[0] }}
                                </li>
                            </ul>
                        </div>
                    </div>
                    <b-overlay :show="loading" rounded="sm">
                        <b-form-group
                            :description="$t('FORM.INFO.FILE', { format: 'Excel (.xls)' })"
                            :label="$t('FORM.LABEL.FILE')"
                            :state="validateState('file')"
                            label-for="file"
                        >
                            <b-form-file
                                v-model="form.file"
                                id="file"
                                name="file"
                                v-validate="{ required: true }"
                                accept=".xls,.xlsx"
                                :state="validateState('file')"
                                :browse-text="$t('CONTROLLERS.BROWSE')"
                                :placeholder="$t('FORM.INPUT.FILE')"
                                aria-describedby="file-feedback"
                                data-vv-as="Excel"
                            >
                            </b-form-file>
                            <b-form-invalid-feedback id="file-feedback">
                                <div :key="error" v-for="error in veeErrors.collect('file')">{{ error }}</div>
                            </b-form-invalid-feedback>
                        </b-form-group>
                        <b-form-group :label="$t('FORM.LABEL.USERS_CUSTOMER')" label-for="customers-select">
                            <b-form-select
                                data-vv-as="Établissement"
                                v-validate="{ required: true }"
                                :state="validateState('customers-select')"
                                id="customers-select"
                                name="customers-select"
                                v-model="form.customer_id"
                                :options="customersOptions"
                            />
                            <b-form-invalid-feedback id="customers-feedback">
                                <div :key="error" v-for="error in veeErrors.collect('customers-select')">{{ error }}</div>
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </b-overlay>
                </b-form>
            </template>
            <template #modal-footer>
                <a :href="`${publicPath}media/import-model.xlsx`" download class="btn btn-outline-secondary">{{
                    $t('CONTROLLERS.DOWNLOAD_MODEL')
                }}</a>
                <b-button variant="primary" @click="send()" :disabled="loading">{{ $t('CONTROLLERS.IMPORT') }}</b-button>
            </template>
        </b-modal>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ApiService from '@/core/services/api.service';
import { LIST_ALL_CLIENT } from '@/core/services/store/api/client.service';
import { validationErrorMsg } from '@/core/services/validation.service';

export default {
    props: {
        customerId: {
            type: Number,
            required: true
        },
        classeId: {
            type: Number,
            required: true
        },
        classesCustomersId: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            VUE_APP_PLATEFORME_URL: process.env.VUE_APP_PLATEFORME_URL,
            error: false,
            hasFileErrors: false,
            importReport: {
                success: [],
                errors: []
            },
            form: {
                file: null,
                customer_id: null
            },
            loading: false,
            isValid: null,
            customersFields: [
                { key: 'code', label: 'Code' },
                { key: 'name', label: 'Établissement correspondant' }
            ],
            customersOptions: [],
            publicPath: process.env.BASE_URL
        };
    },
    watch: {
        customerId: function () {
            this.getCustomers();
        }
    },
    computed: {
        ...mapGetters({
            customers: 'getClients'
        })
    },
    methods: {
        validateState(ref) {
            if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
                return !this.veeErrors.has(ref);
            }
            return null;
        },
        getCustomers() {
            this.loading = true;
            const data = {
                params: {
                    customer_id: this.customerId,
                    force: true
                }
            };
            this.$store.dispatch(LIST_ALL_CLIENT, data).then((result) => {
                this.customersOptions = [
                    {
                        value: null,
                        text: 'Aucun établissement sélectionné'
                    }
                ];
                result &&
                    result.data &&
                    result.data.data.map((customer) => {
                        this.customersOptions.push({
                            value: customer.id,
                            text: `${customer.name} (${customer.code})`
                        });
                    });
                this.loading = false;
            });
        },
        async send() {
            this.error = false;
            this.hasFileErrors = false;
            this.importReport = { success: [], errors: [] };
            const result = await this.$validator.validateAll();

            if (!result) return;
            this.loading = true;
            let data = new FormData();
            data.append('customer_id', this.form.customer_id);
            data.append('classe_id', this.classeId);
            data.append('classes_customers_id', this.classesCustomersId);
            data.append('file', this.form.file);
            ApiService.post('academy/classeUsers/import', data)
                .then((response) => {
                    if (response.data) {
                        if (response.data.errors && response.data.errors.length > 0) {
                            this.hasFileErrors = true;
                            this.error = true;
                            this.importReport = response.data;
                        } else if (response.data.imported === 0) {
                            this.error = true;
                        } else {
                            this.importReport = response.data;
                            this.$bvToast.toast('Le fichier a été entièrement importé.', {
                                title: 'Succès : ',
                                variant: 'success',
                                solid: true
                            });
                            this.$bvModal.hide('excel-import-modal');
                        }
                    }
                    this.form = {
                        file: null,
                        customer_id: null
                    };
                    this.$validator.reset();
                })
                .catch((error) => {
                    this.veeErrors.add(validationErrorMsg(error));
                    this.form = {
                        file: null,
                        customer_id: null
                    };
                    this.$validator.reset();
                })
                .finally(() => {
                    this.$emit('updateClientsClasses');
                    this.loading = false;
                    this.form = {
                        file: null,
                        customer_id: null
                    };
                });
        },
        reset() {
            this.form = {
                file: null,
                customer_id: null
            };
            this.error = false;
            this.importReport = {
                success: [],
                errors: []
            };
            this.$validator.reset();
        }
    }
};
</script>

<style lang="scss" scoped>
.import-report {
    margin-bottom: 1rem;

    .report-section {
        padding: 1rem;
        border-radius: 8px;
        margin-bottom: 1rem;

        &:last-child {
            margin-bottom: 0;
        }

        .section-header {
            display: flex;
            align-items: center;
            margin-bottom: 0.5rem;

            i {
                margin-right: 0.5rem;
                font-size: 1.2rem;
            }
        }

        ul {
            list-style-type: none;
            padding-left: 1.7rem;
            margin: 0;

            li {
                position: relative;
                padding: 0.2rem 0;

                &::before {
                    content: '•';
                    position: absolute;
                    left: -1rem;
                }
            }
        }
    }

    .error-section {
        background-color: #fff3f3;
        border: 1px solid #ffcdd2;

        .section-header {
            color: #d32f2f;
        }
    }

    .success-section {
        background-color: #f1f8f1;
        border: 1px solid #c8e6c9;

        .section-header {
            color: #2e7d32;
        }
    }
}
</style>
