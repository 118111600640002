import ApiService from '@/core/services/api.service';

export const ACTIVATE_CLASSROOM = 'activateClassroom';
export const SET_CLASSROOM = 'setClassroom';
export const GET_VISIO = 'getVisio';
export const UPDATE_VISIO = 'updateVisio';
export const SET_VISIO = 'setVisio';
export const SET_ERROR = 'setError';

const state = {
    errors: null,
    classroom: {},
    visio: {}
};

const getters = {
    classroom(state) {
        return state.classroom;
    },
    getVisio(state) {
        return state.visio;
    },
    updateVisio(state) {
        return state.visio;
    }
};

const actions = {
    async [ACTIVATE_CLASSROOM](context, classroomId) {
        try {
            const result = await ApiService.query(`academy/videoconference/activate/${classroomId}`);
            context.commit(SET_CLASSROOM, result.data);
            return result;
        } catch (error) {
            throw error;
        }
    },
    async [GET_VISIO](context, data) {
        try {
            const result = await ApiService.query('academy/videoconference/' + data);
            context.commit(SET_VISIO, result.data);
            return result;
        } catch (error) {
            throw error;
        }
    },
    async [UPDATE_VISIO](context, data) {
        try {
            const result = await ApiService.put('academy/videoconference/' + data.id, data);
            context.commit(SET_VISIO, result.data);
            return result;
        } catch (error) {
            throw error.response.data.error;
        }
    }
};

const mutations = {
    [SET_CLASSROOM](state, classroom) {
        state.classroom = classroom;
    },
    [SET_VISIO](state, classroom) {
        state.visio = classroom;
    },
    [SET_ERROR](state, error) {
        state.errors = error;
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};
