<template>
    <div>
        <div v-if="loading" class="card card-custom col-lg-13 col-xxl-12 mt-5">
            <Loader></Loader>
        </div>
        <div v-else>
            <b-button
                v-if="!showAllVisios"
                id="chatToggle"
                @click="showChat = !showChat"
                variant="outline-primary"
                pill
                v-b-tooltip.hover.top="showChat ? 'Masquer le chat' : 'Ouvrir le chat'"
            >
                <b-icon icon="chat" style="width: 100%; height: auto;"></b-icon>
            </b-button>
            <div class="card card-custom gutter-b flex-row align-items-center justify-content-between p-5 mt-5">
                <div class="w-25">
                    <router-link :to="{ name: 'classrooms' }" replace>
                        <span class="btn btn-outline-danger btn-md">
                            <span class="svg-icon svg-icon-md svg-icon-light-danger">
                                <inline-svg src="/media/svg/icons/Navigation/Arrow-left.svg"></inline-svg>
                            </span>
                            {{ !mobile() ? $t('CLASS.BACK') : '' }}
                        </span>
                    </router-link>
                </div>
                <div class="d-flex flex-column align-items-center">
                    <h1 class="m-0 align-self-center w-100">{{ classroom.classe.title }}</h1>
                </div>
                <div class="w-25" v-if="!mobile()"></div>
            </div>
            <div v-if="!showAllVisios" class="d-flex">
                <div class="w-100" :class="showChat ? 'mr-5' : ''">
                    <div class="d-flex justify-content-between align-items-center" :class="mobile() ? 'flex-column' : ''">
                        <!--Description de la classeroom-->
                        <div
                            class="card card-custom gutter-b d-flex flex-row flex-wrap justify-content-around align-items-center py-2 w-100"
                            :style="mobile() ? '' : 'height: 20rem;'"
                        >
                            <img
                                :src="classroom.classe.miniature"
                                ref="EditMiniature"
                                alt="text"
                                style="max-width: 60%;max-height:100%;vertical-align:middle"
                                class="m-0 rounded"
                                :class="mobile() ? 'mb-10' : ''"
                            />
                            <div
                                style="min-width: 40%; max-height: 95%"
                                class="d-flex flex-column justify-content-center px-5"
                                :style="mobile() ? 'max-width:100%;' : 'max-width:55%;'"
                            >
                                <span class="font-weight-bold pb-2">{{ $t('CLASS.DESCRIPTION.LABEL') }}</span>
                                <p>{{ classroom.classe.description }}</p>
                                <p>
                                    <span class="font-weight-bold pb-2">{{ $t('CLASS.TUTOR.LABEL') }}</span>
                                    {{ classroom.tuteur.lastname.toUpperCase() }} {{ classroom.tuteur.firstname }}
                                </p>
                                <p>
                                    <span class="font-weight-bold pb-2">{{ $t('CLASS.DATE.LABEL') }}</span> Du
                                    <span class="font-weight-bold">{{ formatDate(classroom.start_date) }}</span> au
                                    <span class="font-weight-bold">{{ formatDate(classroom.end_date) }}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <!--Boite à outils-->
                    <div class="card card-custom gutter-b p-2 align-items-center w-100">
                        <div class="w-100 h-100 mx-auto d-flex justify-content-around">
                            <b-button
                                pill
                                variant="outline-primary"
                                class="d-flex justify-content-center align-items-center"
                                v-b-modal.create-visio-modal
                            >
                                <b-icon icon="plus" class="mr-2"></b-icon>
                                Créer une vidéoconférence
                            </b-button>
                            <b-modal
                                id="create-visio-modal"
                                title="Créer une vidéoconférence"
                                ok-title="Confirmer"
                                cancel-title="Annuler"
                                @ok="createVisio()"
                            >
                                <b-form>
                                    <b-form-group id="input-group-1" label="Titre :" label-for="input-1">
                                        <b-form-input
                                            id="input-1"
                                            v-model="visio.name"
                                            required
                                            placeholder="Titre de la vidéoconférence"
                                        ></b-form-input>
                                    </b-form-group>
                                </b-form>
                            </b-modal>
                            <b-button
                                pill
                                variant="outline-primary"
                                class="d-flex justify-content-center align-items-center"
                                @click="$bvModal.show('start-classroom-modal')"
                                :disabled="classroom.started === 1"
                            >
                                <b-icon v-if="!activatingSession" icon="play" class="mr-2"></b-icon>
                                <b-spinner v-else small variant="light" class="mr-3"></b-spinner>
                                {{ classroom.started === 1 ? 'Session démarrée' : 'Démarrer la session' }}
                            </b-button>
                            <b-modal
                                id="start-classroom-modal"
                                title="Démarrer la session"
                                ok-title="Confirmer"
                                cancel-title="Annuler"
                                @ok="startClassroom()"
                            >
                                <p>Êtes vous sûr de vouloir démarrer la session ?</p>
                            </b-modal>
                            <b-button
                                pill
                                variant="outline-primary"
                                class="d-flex justify-content-center align-items-center"
                                @click="showAllVisios = true"
                            >
                                <b-icon icon="person" class="mr-2"></b-icon>
                                Liste des visios ({{ classroom.videoconferences.length }})
                            </b-button>
                        </div>
                    </div>
                    <!--Visios-->
                    <div class="card card-custom gutter-b p-5 align-items-center w-100">
                        <div class="w-100 h-100 mx-auto d-flex flex-column align-items-center">
                            <Visios
                                :key="key"
                                @reloadVisios="getClassroom()"
                                @showAllVisio="updateparent"
                                :videoconferences="classroom.videoconferences"
                            ></Visios>
                        </div>
                    </div>
                    <div class="d-flex justify-content-between align-items-center" :class="mobile() ? 'flex-column' : ''">
                        <!--Liste des utilisateurs inscrits à la session-->
                        <div class="card card-custom gutter-b p-5 align-items-center w-100">
                            <div class="w-100 h-100 mx-auto d-flex flex-column">
                                <h4>Liste des stagiaires</h4>
                                <b-table :items="classroom.stagiaires" :fields="fields" responsive hover>
                                    <template #cell(infos.CNAPS)="row">
                                        <p class="m-0" v-if="row.item.infos.CNAPS">{{ row.item.infos.CNAPS }}</p>
                                        <p class="m-0" v-else>Non renseigné</p>
                                    </template>
                                    <template #cell(result.duration)="row">
                                        <p class="m-0">{{ convertTime(row.item.result.duration) }}</p>
                                    </template>
                                    <template #cell(progress)="row">
                                        <b-progress show-progress>
                                            <b-progress-bar :value="(row.item.result.totalFinished / row.item.result.total) * 100">
                                                <span>
                                                    <strong>{{ row.item.result.totalFinished }} / {{ row.item.result.total }}</strong>
                                                </span>
                                            </b-progress-bar>
                                            <div
                                                v-if="row.item.result.totalFinished === 0"
                                                class="d-flex justify-content-center align-items-center w-100"
                                            >
                                                <strong>{{ row.item.result.totalFinished }} / {{ row.item.result.total }}</strong>
                                            </div>
                                        </b-progress>
                                    </template>
                                    <template #cell(state)="row">
                                        <p v-if="row.item.state === 1" class="m-0">Non commencé</p>
                                        <p v-if="row.item.state === 2" class="m-0">En cours</p>
                                        <p v-if="row.item.state === 3" class="m-0">Terminé</p>
                                    </template>
                                    <template #cell(action)="row">
                                        <router-link :to="{ name: 'classes-customers.link' }" replace>
                                            <button class="btn btn-icon btn-circle btn-light btn-sm">
                                                <span class="svg-icon svg-icon-md svg-icon-primary">
                                                    <inline-svg src="/media/svg/icons/General/Visible.svg" />
                                                </span>
                                            </button>
                                        </router-link>
                                    </template>
                                </b-table>
                            </div>
                        </div>
                    </div>
                </div>
                <!--chat-->
                <div
                    v-if="showChat"
                    class="card card-custom gutter-b d-flex flex-row flex-wrap justify-content-around align-items-center py-2"
                    style="width: 40%;"
                >
                    <h1>LE CHAT</h1>
                </div>
            </div>
            <VisiosList v-else @reloadVisios="getClassroom()" @showAllVisio="updateparent" :session="classroom"></VisiosList>
        </div>
    </div>
</template>
<script>
import { GET_CLASSROOM } from '../../core/services/store/api/classroom.service';
import moment from 'moment/moment';
import ApiService from '../../core/services/api.service';

export default {
    name: 'Classroom',
    components: {
        Visios: () => import('../content/components/Visios.vue'),
        VisiosList: () => import('../content/components/VisiosList.vue'),
        Loader: () => import('../layout/components/Loader.vue')
    },
    data() {
        return {
            key: 1,
            tuteur: false,
            loading: true,
            classroom: {},
            showAllVisios: false,
            showChat: false,
            activatingSession: false,
            visio: {
                name: '',
                start: '',
                duration: '',
                training_time: ''
            },
            fields: [
                { key: 'infos.lastname', label: 'Nom', tdClass: 'align-middle' },
                { key: 'infos.firstname', label: 'Prénom', tdClass: 'align-middle' },
                { key: 'infos.CNAPS', label: 'Autorisation CNAPS', tdClass: 'align-middle' },
                { key: 'state', label: 'Statut', tdClass: 'align-middle' },
                { key: 'result.duration', label: 'Temps passé', tdClass: 'align-middle' },
                { key: 'progress', label: 'Leçons terminées', tdClass: 'align-middle' },
                {
                    key: 'action',
                    label: '',
                    thStyle: 'width: 5%',
                    tdStyle: 'width: 5%',
                    tdClass: 'align-middle text-center'
                }
            ]
        };
    },
    async mounted() {
        if (!this.$route.params.id) {
            this.$router.push({ name: 'dashboard' });
        } else {
            this.checkTuteur([1]);
            await this.getClassroom();
        }
    },
    methods: {
        convertTime(time) {
            //time est en seconde, convertie le en heure minute seconde
            let hours = Math.floor(time / 3600);
            let minutes = Math.floor((time - hours * 3600) / 60);
            let seconds = time - hours * 3600 - minutes * 60;
            // if hours = 0 on ne les affiche pas
            if (hours === 0) {
                hours = '';
            } else {
                hours = hours + 'h ';
            }
            return hours + minutes + 'm ' + seconds + 's';
        },
        createVisio() {
            this.$bvModal.show('create-visio-modal');
        },
        startClassroom() {
            this.activatingSession = true;
            let data = {
                started: 1
            };
            ApiService.put(process.env.VUE_APP_API_URL + '/academy/classroom/' + this.classroom.id, data).then(() => {
                this.$bvToast.toast('La session est maintenant accessible aux stagiaires.', {
                    title: 'Succès',
                    variant: 'success',
                    solid: true
                });
                this.getClassroom();
            });
        },
        updateparent(variable) {
            this.showAllVisios = variable;
        },
        mobile() {
            return window.screen.availWidth <= 992;
        },
        formatDate(date) {
            return moment(date).format('DD/MM/YYYY');
        },
        getClassroom() {
            this.$store.dispatch(GET_CLASSROOM, this.$route.params.id).then(response => {
                //Si l'utilisateur connecté est un tuteur, on vérifie qu'il est bien tuteur de la session
                if (this.tuteur) {
                    if (
                        response.data.tuteur_id !== this.$store.getters.currentUser.id &&
                        this.$store.getters.currentUser.role !== 'admin'
                    ) {
                        this.$router.push({ name: 'dashboard' });
                    } else {
                        this.loading = false;
                    }
                } else {
                    this.loading = false;
                }
                this.classroom = response.data;
                this.key = this.key + 1;
                this.activatingSession = false;
            });
        },
        checkTuteur(rightIds) {
            if (
                this.$store.getters.currentUser &&
                this.$store.getters.currentUser.rights &&
                this.$store.getters.currentUser.rights.academy
            ) {
                this.$store.getters.currentUser.rights.academy.forEach(right => {
                    if (rightIds.includes(right.right_id)) {
                        this.tuteur = true;
                    }
                });
            }
        }
    }
};
</script>
<style>
#chatToggle {
    position: fixed;
    top: 20%;
    right: 0;
    z-index: 999;
    width: 50px;
    height: 50px;
    transition: all 0.3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
