<template>
    <div>
        <div
            class="card card-custom gutter-b p-5 w-100"
        >
            <div class="w-100 d-flex flex-row flex-wrap justify-content-around align-items-center mb-5">
                <div class="w-25">
                    <span class="btn btn-outline-danger btn-md" @click="showAll()" id="closeContainer">
                    <span class="svg-icon svg-icon-md svg-icon-light-danger m-0">
                        <svg height="1em" viewBox="0 0 384 512" xmlns="http://www.w3.org/2000/svg" id="close">
                            <!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                            <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/></svg>
                    </span>
                </span>
                </div>
                <div class="w-50">
                    <h3 class="text-center">Session du {{ formatDate(session.start_date) }} au
                        {{ formatDate(session.end_date) }}</h3>
                    <h5 class="text-center">Liste des videosconférences de la classe</h5>
                </div>
                <div v-if="!mobile()" class="w-25"></div>
            </div>
            <b-table
                :fields="fields"
                :items="session.videoconferences"
                class="mx-0 p-0 mb-5"
                hover
                no-border-collapse
                responsive
                style="vertical-align: middle; text-align: center"
            >
                <template #cell(show_details)="row">
                    <button class="btn btn-icon btn-light btn-sm" @click="row.toggleDetails">
                        <span class="svg-icon svg-icon-md svg-icon-primary">
                            <inline-svg
                                :src="'/media/svg/icons/Navigation/' + `${row.detailsShowing ? 'Minus' : 'Plus'}` + '.svg'"/>
                        </span>
                    </button>
                </template>
                <template #cell(start)="row">
                    {{ formatDateWithTime(row.item.start) }}
                </template>
                <template #cell(duration)="row">
                    {{ row.item.duration }} minutes
                </template>
                <template #cell(training_time)="row">
                    {{ row.item.training_time ? 'Oui' : 'Non' }}
                </template>
                <template #cell(Action)="row">
                    <div class="d-flex justify-content-center align-items-center">
                        <button v-b-tooltip.hover.top="'Inscrire des stagiaires'"
                                class="btn btn-icon btn-circle btn-light btn-sm mr-2"
                                @click="$bvModal.show('modal-inscription-' + row.item.id)">
                            <span class="svg-icon svg-icon-md svg-icon-primary">
                                <i class="fas fa-user-plus"></i>
                            </span>
                        </button>
                        <RegisterIntern :visio="row.item"></RegisterIntern>
                        <button v-b-tooltip.hover.top="'Modifier'"
                                class="btn btn-icon btn-circle btn-light btn-sm mr-2"
                                @click="editVisios(row.item)">
                             <span class="svg-icon svg-icon-md">
                                    <inline-svg src="/media/svg/icons/General/Edit.svg"></inline-svg>
                                </span>
                        </button>
                    </div>
                </template>
                <template #row-details="row">
                    <b-table :fields="fieldsStagiaires" :items="row.item.users" hover show-empty>
                        <template #cell(signature)="row">
                            <img v-if="row.item.signature" :src="row.item.signatureUrl" alt="Signature du stagiaire"
                                 style="height: 5rem">
                            <p v-else class="m-0">Pas de signature</p>
                        </template>
                        <template #cell(is_present)="row">
                            <p class="m-0">{{ row.item.is_present ? 'Oui' : 'Non' }}</p>
                        </template>
                        <template #empty="scope">
                            Aucun stagiaire.
                        </template>
                    </b-table>
                </template>
            </b-table>
            <UpdateVisio :selectedVisio="selectedVisio" @reloadVisios="reloadVisios()"></UpdateVisio>
        </div>
    </div>
</template>
<script>
import moment from "moment/moment";

export default {
    name: "VisiosList",
    props: {
        session: {
            type: Object,
            required: true
        }
    },
    components: {
        RegisterIntern: () => import('@/view/content/components/RegisterIntern.vue'),
        UpdateVisio: () => import('./UpdateVisio.vue')
    },
    data() {
        return {
            fields: [
                {
                    key: 'show_details',
                    label: '',
                    thStyle: 'width: 5%',
                    tdStyle: 'width: 5%',
                    tdClass: 'align-middle'
                },
                {key: 'name', label: 'Nom', sortable: true},
                {key: 'start', label: 'Date', sortable: true},
                {key: 'duration', label: 'Durée', sortable: true},
                {key: 'training_time', label: 'Compte dans le temps de formation', sortable: true},
                {key: 'Action', label: '', sortable: false},
            ],
            fieldsStagiaires: [
                {
                    key: 'lastname', label: 'Nom', sortable: true, tdClass: 'align-middle'
                },
                {
                    key: 'firstname', label: 'Prénom', sortable: true, tdClass: 'align-middle'
                },
                {
                    key: 'is_present', label: 'Présent', sortable: true, tdClass: 'align-middle'
                },
                {
                    key: 'signature', label: 'Signature', sortable: false, tdClass: 'align-middle'
                },
            ],
            sortBy: 'start',
            sortDesc: false,
            perPage: 5,
            selectedVisio: {
                id: null,
                name: null,
                startDate: null,
                startTime: null,
                duration: null,
                training_time: null,
            }
        }
    },
    methods: {
        editVisios(visio) {
            this.selectedVisio.id = visio.id;
            this.selectedVisio.name = visio.name;
            this.selectedVisio.startDate = moment(visio.start).format('YYYY-MM-DD');
            this.selectedVisio.startTime = moment(visio.start).format('HH:mm');
            this.selectedVisio.duration = visio.duration;
            this.selectedVisio.training_time = visio.training_time;
            this.$bvModal.show('edit-visio');
        },
        formatDateWithTime(value) {
            if (value) {
                return moment(String(value)).format('DD/MM/YYYY' + ' à ' + 'HH:mm');
            }
        },
        formatDate(value) {
            if (value) {
                return moment(String(value)).format('DD/MM/YYYY');
            }
        },
        showAll() {
            this.$emit('showAllVisio', false);
        },
        mobile() {
            return this.$vuetify.breakpoint.width < 768;
        },
        reloadVisios() {
            this.$emit('reloadVisios');
        }
    }
}
</script>
<style>
    #close {
        fill: #f64e60;
    }
    #closeContainer:hover #close {
        fill: white;
    }
</style>
